import { Loading } from '@actinc/dls/components/Loading';
import React from 'react';

import Redirect from '~/components/Redirect';
import NAMES from '~/constants/names';
import NEXT from '~/constants/next';
import { ROUTES } from '~/constants/routes';
import getCurrentUrl from '~/helpers/getCurrentUrl';
import localCache from '~/helpers/localCache';
import { isPathEquivalent } from '~/helpers/path';
import { IAuthProps } from '~/helpers/userHasAccessToModuleOnSecondarySchool';
import useActiveUser from '~/hooks/useActiveUser';
import useLogger from '~/hooks/useLogger';
import useQueryParam from '~/hooks/useQueryParam';
import { clearTokens } from '~/hooks/useToken';
import useURLPermissions from '~/hooks/useURLPermissions';

export type AuthAccountProps = {
  children: React.ReactElement<unknown> | React.ReactElement<unknown>[];
} & IAuthProps;

const AuthAccount: React.FC<AuthAccountProps> = ({ children }: AuthAccountProps): React.ReactElement<unknown> => {
  const logger = useLogger('AUTH_ACCOUNT');
  const me = useActiveUser();
  const [returnUrl, setReturnUrl] = React.useState<string | string[] | undefined>();

  const userHasAccessToUrl = useURLPermissions();

  const queryParam = useQueryParam('returnUrl');

  React.useEffect(() => {
    const deepLinking = (): string | string[] | undefined => {
      const currentUrl = getCurrentUrl() as string;
      if (queryParam) {
        return queryParam;
      }
      return !!currentUrl && !isPathEquivalent(currentUrl, ROUTES.INDEX) ? encodeURIComponent(currentUrl) : undefined;
    };

    setReturnUrl(deepLinking());
  }, [queryParam]);

  if (userHasAccessToUrl === null) {
    return <Loading />;
  }

  if (!userHasAccessToUrl) {
    if (!me) {
      localCache.set(NAMES.SCHOOL_ID, undefined);
      return (
        <Redirect
          params={{
            returnUrl,
          }}
          to={ROUTES.LOGIN}
        />
      );
    }
    return <Redirect to={ROUTES.INDEX} />;
  }

  if (NEXT.IS_CLIENT && !me) {
    const currentUrl = getCurrentUrl() as string;
    clearTokens();
    logger.debug('AuthAccount Redirecting: ', currentUrl, ROUTES.LOGIN, { returnUrl });
    return (
      <Redirect
        params={{
          returnUrl,
        }}
        to={ROUTES.LOGIN}
      />
    );
  }

  logger.debug('AuthAccount rendering: ', children);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return children ? <>{children}</> : <></>;
};

export default AuthAccount;
